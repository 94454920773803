<template>
    <div class="input-wrap">
        <select
            ref="input"
            v-bind="attributes"
        />
    </div>
</template>

<script>
import {
    emptyObject, isObject, isset, sortObjectByProperty,
} from '@/utils/functions';

export default {
    name: 'XSelect',
    inheritAttrs: false,
    props: {
        name: String,
        label: String,
        options: Object,
        type: String,
        placeholder: String,
        tags: Boolean,
        allowClear: Boolean,
        fixResizeMultiple: {
            type: Boolean,
            default: false,
        },
        isAsync: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: () => ({}),
        },
        sortByText: {
            type: Boolean,
            default: false,
        },
        sortCallback: {
            type: Function,
        },
        value: [String, Number, Array, Object],
        dropdownZIndex: {
            type: Number,
            default: 0,
        },
        sortingField: {
            type: String,
            default: undefined,
        },
        templateSelection: {
            type: Boolean,
            default: false,
        },
        templateResult: {
            type: Boolean,
            default: false,
        },
        clearAfterSelect: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            alreadyInit: false,
        };
    },
    computed: {
        attributes() {
            let op = { ...this.$attrs, ...this.options };
            delete op.options;
            if (isset(op, 'class')) {
                op.class += ' select2';
            } else {
                op.class = 'select2';
            }
            return op;
        },
        groupNames() {
            return {
                taxes: this.$t('finance', 'Taxes'),
                taxGroups: this.$t('finance', 'Tax groups'),
            };
        },
    },
    watch: {
        value(newValue) {
            let valueChanged = false;
            if (Array.isArray(newValue)) {
                let values = [];
                newValue.forEach((item) => {
                    if (typeof item === 'string') {
                        values.push(item);
                    } else {
                        values.push(item.id);
                    }
                });
                let curVal = $(this.$refs.input).val();
                valueChanged = ((!Array.isArray(curVal) && curVal != values.join(',')) || (Array.isArray(curVal) && values.join(',') !== curVal.join(',')));
            } else if (typeof newValue === 'object' && newValue !== null) {
                valueChanged = newValue.id != $(this.$refs.input).val();
            } else {
                valueChanged = newValue != $(this.$refs.input).val();
            }

            if (newValue === null) {
                newValue = '';
            }

            if (valueChanged) {
                if (Array.isArray(newValue)) {
                    $(this.$refs.input).val(newValue).trigger('change');
                } else if (typeof newValue === 'object' && newValue !== null) {
                    $(this.$refs.input).select2('trigger', 'select', {
                        data: newValue,
                    });
                } else {
                    $(this.$refs.input).val(newValue).trigger('change');
                }
            }
        },
        'options.options': function (newOptions, old) {
            if (this.alreadyInit) {
                if (JSON.stringify(newOptions) != JSON.stringify(old) && (!emptyObject(newOptions) || !emptyObject(old))) {
                    this.reInit();
                }
            }
        },
    },
    mounted() {
        this.reInit();
        this.alreadyInit = true;
        splynx_event_bus.on('before_switch_page', this.close);
    },
    beforeDestroy() {
        this.destroy();
    },
    methods: {
        reInit() {
            let config = {};
            if (this.isAsync) {
                let defaultConfig = this.getDefaultAsyncConfig();
                $.extend(true, config, defaultConfig, this.config);
                if (typeof config.ajax.data === 'string') {
                    config.ajax.data = Function('params', config.ajax.data);
                }
                if (typeof config.ajax.processResults === 'string') {
                    config.ajax.processResults = Function('data', config.ajax.processResults);
                }
            } else {
                config = {
                    data: this.getPreparedOptions(),
                    tags: this.tags,
                    allowClear: this.allowClear,
                    placeholder: this.placeholder,
                };
                if (this.templateResult) {
                    $.extend(true, config, { templateResult: this.formatSelection });
                }
                if (this.templateSelection) {
                    $.extend(true, config, { templateSelection: this.formatSelection });
                }
                $.extend(true, config, this.config);
            }
            this.initHooks(config);
        },
        initHooks(config) {
            let select = null;
            let element = $(this.$refs.input);

            if (element.data('select2')) {
                element.select2('destroy');
                element.find('option,optgroup').remove();
            }

            select = element.select2(config);
            if (this.isAsync && !emptyObject(this.options?.options) && element.find(`option[value='${this.value}']`).length === 0) {
                if (element.find(`option[value='${this.value}']`).length) {
                    element.val(this.value).trigger('change');
                } else {
                    Object.keys(this.options.options).forEach((key) => {
                        let newOption = new Option(this.options.options[key], key, true, true);
                        element.append(newOption);
                    });
                }
            }

            select.val(this.value).trigger('change');

            select.change(this.inputHandler);

            if (this.fixResizeMultiple) {
                select.data('select2').on('results:message', function () {
                    this.dropdown._resizeDropdown();
                    this.dropdown._positionDropdown();
                });
            }

            // @see https://github.com/select2/select2/issues/5993
            select.on('select2:open', this.onOpenSelect);
        },
        inputHandler(event) {
            let val;
            if (this.isAsync) {
                val = $(event.target).select2('data');
                if (val.length < 1) {
                    val = event.target.value;
                } else {
                    let result = [];
                    val.forEach((item) => {
                        result.push({
                            id: item.id,
                            text: item.text,
                        });
                    });
                    val = result;
                }

                if (this.clearAfterSelect) {
                    this.$nextTick(() => {
                        $(event.target).val(null).trigger('change.select2');
                    });
                }
            } else {
                val = $(event.target).val() ?? '';
            }

            this.$emit('input', val);
            this.$emit('change', val);

            if (this.tags) {
                $(window).off('paste');
            }
        },
        getPreparedOptions(optionsForPrepare) {
            let options = [];
            if (emptyObject(this.options.options) && emptyObject(optionsForPrepare)) {
                return options;
            }

            let optionsForProcessing = this.options.options;
            if (!emptyObject(optionsForPrepare)) {
                optionsForProcessing = optionsForPrepare;
            }

            if (this.sortingField !== undefined) {
                optionsForProcessing = Array.from(sortObjectByProperty(optionsForProcessing, this.sortingField, false, true).values());
            }

            for (const key in optionsForProcessing) {
                if (!Object.prototype.hasOwnProperty.call(optionsForProcessing, key)) {
                    continue;
                }

                if (typeof optionsForProcessing[key] === 'string' || typeof optionsForProcessing[key] === 'number') {
                    options.push({
                        text: optionsForProcessing[key],
                        id: key,
                    });
                } else if (isset(optionsForProcessing[key], 'key') && isset(optionsForProcessing[key], 'value')) {
                    options.push({
                        text: optionsForProcessing[key].value,
                        id: optionsForProcessing[key].key,
                    });
                } else if (isset(optionsForProcessing[key], 'id') && isset(optionsForProcessing[key], 'text')) {
                    let option = {
                        text: optionsForProcessing[key].text,
                        id: optionsForProcessing[key].id,
                    };

                    if ('badge' in optionsForProcessing[key]) {
                        option.badge = optionsForProcessing[key].badge;
                    }

                    options.push(option);
                } else {
                    let children = [];
                    if (isObject(optionsForProcessing[key])) {
                        $.each(optionsForProcessing[key], (key, value) => {
                            children.push({
                                text: value,
                                id: key,
                            });
                        });
                    } else {
                        for (const item in optionsForProcessing[key]) {
                            children.push({
                                text: optionsForProcessing[key][item].text,
                                id: optionsForProcessing[key][item].id,
                                badge: optionsForProcessing[key][item].badge,
                            });
                        }
                    }

                    options.push({
                        text: key,
                        children,
                    });
                }
            }

            if (this.sortByText) {
                options.sort((a, b) => {
                    a = a.text.toUpperCase();
                    b = b.text.toUpperCase();
                    if (a === 'ALL' || a === 'ANY' || a === 'ANYONE') {
                        return -1;
                    }
                    if (b === 'ALL' || b === 'ANY' || b === 'ANYONE') {
                        return 1;
                    }

                    return (a > b) ? 1 : -1;
                });
                options.forEach((v) => {
                    if (isset(v, 'children')) {
                        v.children.sort((a, b) => ((a.text.toUpperCase() > b.text.toUpperCase()) ? 1 : -1));
                    }
                });
            }

            if (this.sortCallback) {
                options = this.sortCallback(options);
            }

            return options;
        },
        getDefaultAsyncConfig() {
            return {
                data: {
                    id: 0,
                    name: '',
                },
                ajax: {
                    url: '',
                    dataType: 'json',
                    delay: 250,
                    cache: true,
                    data(params) {
                        return {
                            value: params.term,
                        };
                    },
                    processResults(data) {
                        return {
                            results: data.data,
                        };
                    },
                },
                allowClear: true,
                placeholder: {},
                minimumInputLength: 2,
            };
        },
        onOpenSelect() {
            if (this.dropdownZIndex !== 0) {
                $('.select2-dropdown').css('z-index', this.dropdownZIndex);
            }

            if (this.tags && this.value && this.type !== 'relation_multiple') {
                setTimeout(() => {
                    let valueElement = $('.select2-container').find('.select2-results__option');
                    valueElement.attr('aria-selected', 'false');
                }, 0);
            }

            if (this.tags && !this.value) {
                $(window).on('paste', this.changeValueAfterPaste);
            }

            this.setFocusToSearchInput();
        },
        setFocusToSearchInput() {
            setTimeout(() => {
                let field = $('.select2-container--open .select2-search__field');
                if (field) {
                    field.get(0)?.setAttribute('tabindex', '1');
                    field.get(0)?.focus();
                }
            }, 100);
        },
        changeValueAfterPaste() {
            let value;
            setTimeout(() => {
                value = $('.select2-container--open').find('.select2-selection__rendered').text();
                this.$emit('input', value);
                $(window).off('paste');
            }, 10);
        },
        formatSelection(option) {
            if (!option) {
                return;
            }
            if (option?.children && Object.keys(this.groupNames).includes(option.text)) {
                option.text = this.groupNames[option.text];
            }
            if (!option.badge) {
                return option.text;
            }

            // If we got badge config without text wee should apply it for main option`s text
            if (!isset(option.badge, ['text']) || option.badge.text === '') {
                return $(`<span><span class="badge ${option.badge.background} text">${option.text}</span></span>`);
            }

            // Add badge with text after main option`s text
            return $(`<span><span class="text">${option.text}</span><span class="badge
                                            ${option.badge.background} ms-4">${option.badge.text}</span></span>`);
        },
        destroy() {
            let element = $(this.$refs.input);
            if (element.data('select2')) {
                element.select2('destroy');
            }
            if (this.tags) {
                $(window).off('paste');
            }
        },
        close() {
            let element = $(this.$refs.input);
            if (element.data('select2')) {
                element.select2('close');
            }
        },
    },
};
</script>
