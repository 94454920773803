function naturalSort(a, b, html) {
    if (typeof a === 'undefined' || a === null) {
        a = '';
    }
    if (typeof b === 'undefined' || b === null) {
        b = '';
    }
    let re = /(^-?[0-9]+(\.?[0-9]*)[df]?e?[0-9]?%?$|^0x[0-9a-f]+$|[0-9]+)/gi;
    let sre = /(^[ ]*|[ ]*$)/g;
    let dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/;
    let hre = /^0x[0-9a-f]+$/i;
    let ore = /^0/;
    // convert all to strings and trim()
    let x = a.toString().replace(sre, '') || '';
    let y = b.toString().replace(sre, '') || '';
    // remove html from strings if desired
    if (!html) {
        x = stripTags(x).trim();
        y = stripTags(y).trim();
    }
    // chunk/tokenize
    let xN = x.replace(re, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0');
    let yN = y.replace(re, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0');
    // numeric, hex or date detection
    let xD = parseInt(x.match(hre), 10) || (xN.length !== 1 && x.match(dre) && Date.parse(x));
    let yD = parseInt(y.match(hre), 10) || xD && y.match(dre) && Date.parse(y) || null;

    // first try and sort Hex codes or Dates
    if (yD) {
        if (xD < yD) {
            return -1;
        }
        if (xD > yD) {
            return 1;
        }
    }

    // natural sorting through split numeric strings and default strings
    for (let cLoc = 0, numS = Math.max(xN.length, yN.length); cLoc < numS; cLoc++) {
        // Fixes undefined error
        if (typeof xN[cLoc] === 'undefined' && typeof yN[cLoc] !== 'undefined') {
            return -1;
        }
        if (typeof xN[cLoc] !== 'undefined' && typeof yN[cLoc] === 'undefined') {
            return 1;
        }
        // find floats not starting with '0', string or 0 if not defined (Clint Priest)
        let oFxNcL = !(xN[cLoc] || '').match(ore) && parseFloat(xN[cLoc], 10) || xN[cLoc] || 0;
        let oFyNcL = !(yN[cLoc] || '').match(ore) && parseFloat(yN[cLoc], 10) || yN[cLoc] || 0;
        // handle numeric vs string comparison - number < string - (Kyle Adams)
        if (isNaN(oFxNcL) !== isNaN(oFyNcL)) {
            return (isNaN(oFxNcL)) ? 1 : -1;
        }
        // rely on string comparison if different types - i.e. '02' < 2 != '02' < '2'
        if (typeof oFxNcL !== typeof oFyNcL) {
            oFxNcL += '';
            oFyNcL += '';
        }
        if (oFxNcL < oFyNcL) {
            return -1;
        }
        if (oFxNcL > oFyNcL) {
            return 1;
        }
    }
    return 0;
}

function stripTags(html) {
    let wrapper = $('<div />').html(html);
    return wrapper.text();
}

jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'natural-asc': function (a, b) {
        return naturalSort(a, b, true);
    },

    'natural-desc': function (a, b) {
        return naturalSort(a, b, true) * -1;
    },

    'natural-nohtml-asc': function (a, b) {
        return naturalSort(a, b, false);
    },

    'natural-nohtml-desc': function (a, b) {
        return naturalSort(a, b, false) * -1;
    },

    'natural-ci-asc': function (a, b) {
        a = a.toString().toLowerCase();
        b = b.toString().toLowerCase();

        return naturalSort(a, b, true);
    },

    'natural-ci-desc': function (a, b) {
        a = a.toString().toLowerCase();
        b = b.toString().toLowerCase();

        return naturalSort(a, b, true) * -1;
    },
});

jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'ip-address-pre': function (a) {
        let i; let
            item;
        let m; let n; let
            t;
        let x; let
            xa;

        if (!a) {
            return 0;
        }

        a = a.replace(/<[\s\S]*?>/g, '');
        // IPv4:Port
        t = a.split(':');
        if (t.length == 2) {
            m = t[0].split('.');
        } else {
            m = a.split('.');
        }
        n = a.split(':');
        x = '';
        xa = '';

        if (m.length == 4) {
            // IPV4
            for (i = 0; i < m.length; i++) {
                item = m[i];

                if (item.length == 1) {
                    x += `00${item}`;
                } else if (item.length == 2) {
                    x += `0${item}`;
                } else {
                    x += item;
                }
            }
        } else if (n.length > 0) {
            // IPV6
            let count = 0;
            for (i = 0; i < n.length; i++) {
                item = n[i];

                if (i > 0) {
                    xa += ':';
                }

                if (item.length === 0) {
                    count += 0;
                } else if (item.length == 1) {
                    xa += `000${item}`;
                    count += 4;
                } else if (item.length == 2) {
                    xa += `00${item}`;
                    count += 4;
                } else if (item.length == 3) {
                    xa += `0${item}`;
                    count += 4;
                } else {
                    xa += item;
                    count += 4;
                }
            }

            // Padding the ::
            n = xa.split(':');
            let paddDone = 0;

            for (i = 0; i < n.length; i++) {
                item = n[i];

                if (item.length === 0 && paddDone === 0) {
                    for (let padding = 0; padding < (32 - count); padding++) {
                        x += '0';
                        paddDone = 1;
                    }
                } else {
                    x += item;
                }
            }
        }

        return x;
    },

    'ip-address-asc': function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },

    'ip-address-desc': function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
});

jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'num-html-pre': function (a) {
        let x = String(a).replace(/<[\s\S]*?>/g, '');
        return parseFloat(x);
    },
    'num-html-asc': function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },
    'num-html-desc': function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
});
jQuery.fn.dataTable.ext.type.order['file-size-pre'] = function (data) {
    if (data === '---') {
        return -1;
    }

    let units = data.replace(/[\d\.\s]/g, '').toLowerCase();
    let multiplier = 1;
    if (units === 'kb') {
        multiplier = 1000;
    } else if (units === 'mb') {
        multiplier = 1000000;
    } else if (units === 'gb') {
        multiplier = 1000000000;
    } else if (units === 'tb') {
        multiplier = 1000000000000;
    } else if (units === 'pb') {
        multiplier = 1000000000000000;
    }
    return parseFloat(data) * multiplier;
};
jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'currency-pre': function (a) {
        a = stripTags(a);
        if (a === '---' || a === '--' || a === '-' || a === '') {
            a = '-1';
        }

        if (typeof a === 'undefined' || a === 0) {
            a = '0';
        }
        a = a.replace(',', '.')
            .replace(/[.'`](?=\d{0,3}[.])/g, '')
            .replace(/(?!-)\D/g, '');

        return parseFloat(a);
    },
    'currency-asc': function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },
    'currency-desc': function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
});

jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'formatted-number-pre': function (a) {
        if (a === '---' || a === '--' || a === '-') {
            a = '-1';
        }

        if (typeof a === 'undefined' || a === 0) {
            a = '0';
        }
        a = a.replace(',', '.')
            .replace(/[.'`](?=\d{0,3}[.])/g, '')
            .replace(/(?!-)\D/g, '');

        return parseFloat(a);
    },
    'formatted-number-asc': function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },
    'formatted-number-desc': function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
});

let _anyNumberSort = function (a, b, high) {
    let reg = /[+-]?((\d+(\.\d*)?)|\.\d+)([eE][+-]?[0-9]+)?/;
    a = a.replace(',', '.').match(reg);
    a = a !== null ? parseFloat(a[0]) : high;
    b = b.replace(',', '.').match(reg);
    b = b !== null ? parseFloat(b[0]) : high;
    return ((a < b) ? -1 : ((a > b) ? 1 : 0));
};

jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'any-number-pre': function (a) {
        if (a === '---' || a === '--' || a === '') {
            a = '-1';
        }

        if (typeof a === 'undefined' || a === 0) {
            a = '0';
        }

        return a;
    },
    'any-number-asc': function (a, b) {
        return _anyNumberSort(a, b, Number.POSITIVE_INFINITY);
    },
    'any-number-desc': function (a, b) {
        return _anyNumberSort(a, b, Number.NEGATIVE_INFINITY) * -1;
    },
});

// sort in datatables by formated date
jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'system-datetime-pre': function (a) {
        let date = moment(a, dt_datetime_format);
        return date.isValid() ? date : 0;
    },
    'system-datetime-asc': function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },

    'system-datetime-desc': function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
    'system-date-pre': function (a) {
        let date = moment(a, dt_datetime_format);
        return date.isValid() ? date : 0;
    },
    'system-date-asc': function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },

    'system-date-desc': function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
});

// Sorting by datetime in html tag's attribute
// Example: <div data-date="2019-03-01 10:00:00">March 1</div>
jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'html-attr-datetime-pre': function (code) {
        // fallback to old sorting without data-sort
        let ret = $(code).attr('data-date');
        if (ret === undefined) {
            ret = $(code).html().toString().replace(/[-]/g, '')
                .trim();
        }
        return ret;
    },
    'html-attr-datetime-asc': function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },
    'html-attr-datetime-desc': function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
});

// Sorting by value in html tag's attribute
// Example: <div data-sort="1">New</div>
jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'html-attr-data-sort-pre': function (code) {
        // fallback to old sorting without data-sort
        let ret = $(code).attr('data-sort');
        if (ret === undefined) {
            ret = $(code).html().toString().replace(/[-]/g, '');
        }
        return parseInt(ret);
    },
    'html-attr-data-sort-asc': function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },
    'html-attr-data-sort-desc': function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
});

// Sorting by value in html tag's attribute as float
// Example: <div data-sort="1.5">New</div>
jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'html-attr-data-sort-float-pre': function (code) {
        // fallback to old sorting without data-sort
        let ret = $(code).attr('data-sort-float');
        if (ret === undefined) {
            ret = $(code).html().toString().replace(/[-]/g, '');
        }
        return parseFloat(ret);
    },
    'html-attr-data-sort-float-asc': function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },
    'html-attr-data-sort-float-desc': function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
});

// Sorting phone numbers
jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'phone-pre': function (value) {
        if (value == '') {
            return 0;
        }

        let intVal = parseInt(value.split(',').splice(0, 1).join('').replace(/\D/g, ''));

        if (Number.isNaN(intVal)) {
            return 0;
        }

        return intVal;
    },
    'phone-asc': function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },
    'phone-desc': function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
});

// Sorting by duration
// Example: 00:12:33
jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'duration-pre': function (value) {
        value += '';
        return Number(value.replace(/\D/g, ''));
    },
    'duration-asc': function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
    'duration-desc': function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },
});

// Sort negative amounts in brackets
jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'negativeAmountsInBracketsSorting-pre': function (a) {
        a = stripTags(a);
        if (a === '---' || a === '--' || a === '-' || a === '') {
            a = '-1';
        }

        if (typeof a === 'undefined' || a === 0) {
            a = '0';
        }

        let isNegative = false;
        if (a.match(/\(.*\)/)) {
            isNegative = true;
            a = a.replace(/[\(\)]/g, '');
        }

        a = a.replace(',', '.')
            .replace(/[.'`](?=\d{0,3}[.])/g, '')
            .replace(/(?!-)\D/g, '');

        let parsedValue = parseFloat(a);

        return isNegative ? -parsedValue : parsedValue;
    },
    'negativeAmountsInBracketsSorting-asc': function (a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },
    'negativeAmountsInBracketsSorting-desc': function (a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },
});

window.matchYearWithMonth = (year, row, meta) => {
    const { idx } = meta.settings.aoColumns.find((column) => column.type === 'month') ?? {};
    if (!idx) {
        return year;
    }
    const month = row[idx];
    const months = window.spl_localization_messages.date;
    const monthNumbers = {
        [months.January]: '01',
        [months.February]: '02',
        [months.March]: '03',
        [months.April]: '04',
        [months.May]: '05',
        [months.June]: '06',
        [months.July]: '07',
        [months.August]: '08',
        [months.September]: '09',
        [months.October]: '10',
        [months.November]: '11',
        [months.December]: '12',
    };
    return `${year}-${monthNumbers[month]}`;
};
